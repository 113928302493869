const BinCategory = [
  {
    label: "Standard Grey Non-Ashtray Bin(Old Design)",
    value: "Standard Grey Non-Ashtray Bin (Old Design)",
    category: "Standard Grey Non-Ashtray Bin(Old Design)",
    color: "#A9A9A9",
    count: 0,
  },
  {
    label: "Standard Grey Ashtray Bin(Old Design)",
    value: "Standard Grey Ashtray Bin (Old Design)",
    category: "Standard Grey Ashtray Bin(Old Design)",
    color: "#4B0082",
    count: 0,
  },
  {
    label: "Standard Grey Non-Ashtray Bin(Ausko)",
    value: "Standard Grey Non-Ashtray Bin (Ausko)",
    category: "Standard Grey Non-Ashtray Bin(Ausko)",
    color: "#FFF700",
    count: 0,
  },
  {
    label: "Standard Grey Ashtray Bin(Ausko)",
    value: "Standard Grey Ashtray Bin (Ausko)",
    category: "Standard Grey Ashtray Bin(Ausko)",
    color: "#FF6F61",
    count: 0,
  },
  {
    label: "Standard Green Non-Ashtray Bin",
    value: "Standard Green Non-Ashtray Bin",
    category: "Standard Green Non-Ashtray Bin",
    color: "#40E0D0",
    count: 0,
  },
  {
    label: "Standard Green Ashtray Bin",
    value: "Standard Green Ashtray Bin",
    category: "Standard Green Ashtray Bin",
    color: "#CCCCFF",
    count: 0,
  },
  {
    label: "STB bin (Orchard)",
    value: "STB bin (Orchard)",
    category: "STB bin (Orchard)",
    color: "#228B22",
    count: 0,
  },
  {
    label: "Recycle Blue Bin",
    value: "Recycle Blue Bin",
    category: "Recycle Blue Bin",
    color: "#6A5ACD",
    count: 0,
  },
  {
    label: "Big Belly Recycle Bin",
    value: "Big Belly Recycle Bin",
    category: "Big Belly Recycle Bin",
    color: "#FFB518",
    count: 0,
  },
  {
    label: "Npark Metal Bin",
    value: "Npark Metal Bin",
    category: "Npark Metal Bin",
    color: "#DDA0DD",
    count: 0,
  },
  {
    label: "Grey Monkey Bin(Old Design)",
    value: "Grey Monkey Bin (Old Design)",
    category: "Grey Monkey Bin(Old Design)",
    color: "#FFF0F5",
    count: 0,
  },
  {
    label: "Steel Cigarette Bin",
    value: "Steel Cigarette Bin",
    category: "Steel Cigarette Bin",
    color: "#BDB76B",
    count: 0,
  },
  {
    label: "Npark Green Bin",
    value: "Npark Green Bin",
    category: "Npark Green Bin",
    color: "#008080",
    count: 0,
  },
  {
    label: "Green Monkey Bin(Old Design)",
    value: "Green Monkey Bin (Old Design)",
    category: "Green Monkey Bin(Old Design)",
    color: "#7FFF00",
    count: 0,
  },
  {
    label: "New Monkey Bin(Ausko)",
    value: "New Monkey Bin (Ausko)",
    category: "New Monkey Bin(Ausko)",
    color: "#FA8072",
    count: 0,
  },
  {
    label: "URA bin",
    value: "URA bin",
    category: "URA bin",
    color: "#336EFF",
    count: 0,
  },
];

export default BinCategory;
