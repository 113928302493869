import { useState, useEffect, useRef, useCallback } from 'react';
import Map, { Layer, Marker, Source, Popup, ScaleControl,
   FullscreenControl, NavigationControl, GeolocateControl } from 'react-map-gl';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css'
import maplibregl from 'maplibre-gl';
import polylineData from '../../Constants/PolyLineData';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import DataServices from '../../Services/DataServices';
import MapWebSocket from '../../WebSockets/MapWebSocket';
import MarkerIcon from '../../Assets/pin.png'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Button, CardActionArea, CardActions } from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import MenuList from '../../Constants/MenuList';
import Loading from '../../Controller/LoadingController/Loading';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import dicData from '../../Constants/dic';
import SensorsIcon from '@mui/icons-material/Sensors';
import PinDropIcon from '@mui/icons-material/PinDrop';
//import WaterDropIcon from '@mui/icons-material/WaterDrop';
import EdgesensorHighIcon from '@mui/icons-material/EdgesensorHigh';
import InvertColorsIcon from '@mui/icons-material/InvertColors';
import WaterDropTwoToneIcon from '@mui/icons-material/WaterDropTwoTone';
//import { ReactComponent as dropImage } from '../../Assets/Mediamodifier-Design.svg';
//import dropImage from '../../Assets/Mediamodifier-Design.svg';
//import { SvgIcon } from '@mui/material';
import CustomIcon from './CustomIcon';
import UrlConfigs from '../../configs/UrlConfig';
import { NightShelter } from '@mui/icons-material';



const MAP_STYLE_URL = UrlConfigs.MAP_STYLE_URL; //'https://maps-{s}.onemap.sg/v3/Default/{z}/{x}/{y}.png'; //'mapbox://styles/mapbox/streets-v11';
const MAPBOX_ACCESS_TOKEN =UrlConfigs.MAPBOX_ACCESS_TOKEN;
//[103.90294597766965,  1.358530165383519]

const moderateColor = '#f7b924';
const criticalColor = '#d92550';
const cleanColor =  '#28a745'; 
const blueColor = '#191970';
const dicColor = '#448EE4'; //'#454545';

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

////mcODCinfo ODCinfo
//1.378426, 103.921354
const initialMapViewState = {
  latitude: 1.3179623906210907,
  longitude: 103.80952185215926,
  zoom: 12
}

function ComponentOne(props) {
  const [getLng, setLng] = useState([])
  const [ODCinfoDetails, setODCinfoDetails]= useState([])
  const [allowMap, setAllowMap]=  useState(true)
  const [showPopup, setShowPopup]=  useState(false)
  const [popUpDetails, setPopUpDetails] = useState(null)
  const [load, setLoad]= useState(false)
  const [viewState, setViewState] = useState(initialMapViewState)
  const [map, setMap] = useState(null)
  const [fullData, setFullData]= useState([])
  const [cleanData, setCleanData]= useState([])
  const [moderateData, setModerateData]= useState([])
  const [criticalData, setCriticalData]= useState([])
  const [dic, setDic]= useState([])
  const [combi, setCombi]= useState([])
  const mapRef = useRef(null);
  const [mapKey, setMapKey] = useState(1);
  const [reset, setReset]= useState(false)

  useEffect(() => {
     goToInitialState();
  }, [props.odcValue]);

  useEffect(() => {
    getMapView();
 }, [reset]);


  const goToInitialState =async()=> {
        setLoad(true)
        const initialArray = []
        await setDic(initialArray)
        await setCombi(initialArray)
        await setLng(initialArray)
        await setFullData(initialArray)
        await setODCinfoDetails(initialArray)
        await setViewState(initialMapViewState)
        await setCleanData(initialArray)
        await setModerateData(initialArray)
        await setCriticalData(initialArray)
        setReset(!reset)
        //getMapView();
  }
  
  const getMapView =async()=>{
    if(props.odcValue == 'dicSensor'){
      let arr = [];
      let prevData;
      //setDic(dicData)
      await DataServices.getDicView().then((response) => {
        console.log("Dic response ", response)
        let res =  response?.data?.data
        setDic(res)
        setLng(res) 
      }).catch((err)=>{
        console.log("Error", err)
      })
     /*  {dicData.map((x, i)=>{
        {x.data.map((y)=>{
          dicData[i].coordinates.push(y.coordinates)
          arr.push(y)
          if(y.severity == "critical" && prevData && prevData.severity=="critical"){
            let criticalLine = [prevData.coordinates, y.coordinates]
            dicData[i].criticalLine.push(criticalLine)
          }
          else if(y.severity == "moderate" && prevData && prevData.severity=="moderate"){
            let moderateLine = [prevData.coordinates, y.coordinates]
            dicData[i].moderateLine.push(moderateLine)
          }
          prevData = y;
        })}
      })}
      console.log(dicData)
      setDic(dicData)
      setLng(arr) */
      setLoad(false)
    }
    else if(props.odcValue == 'smartCombi'){ 
      //setCombi(dicData)
      let pCoordinates = []
      let mCoordinates = []
      let cCoordinates = []
      let aCoordinates = []
      let mapData = []
      dicData && dicData.map((x, i) =>{
        aCoordinates.push([parseFloat(x.longitude), parseFloat(x.latitude)]);
        if(x.severity == 'clean'){
          pCoordinates.push([parseFloat(x.longitude), parseFloat(x.latitude)])
        }
        if(x.severity == 'moderate'){
          mCoordinates.push([parseFloat(x.longitude), parseFloat(x.latitude)])
        }
        if(x.severity == 'critical'){
          cCoordinates.push([parseFloat(x.longitude), parseFloat(x.latitude)])
        }
      })
      let newLine = {
        clean: pCoordinates,
        moderate: mCoordinates,
        critical: cCoordinates,
        fullData: aCoordinates
      }
      mapData.push(newLine)
      setCombi(mapData)
      setLng(dicData)
      setLoad(false)
      console.log("smartCombi")
    }
    else{
      let data = {
        type : props.odcValue
      }
      await DataServices.getMapView(data).then((response) => {
        let val = response && response.data && response.data.data;
        console.log("ndnd", val)
        let lastLatLong;     
        let odcInfo = [...ODCinfoDetails]
        let pCoordinates = []
        let mCoordinates = []
        let cCoordinates = []
        let allLng = [];
        let mapData = []
        val.length > 0 && val.map((x, i)=>{
          let checkSeverity = getSeverity(x);        
          let sCheck = checkSeverity == null ? 'clean' : checkSeverity;
          if(x.durationInMinutesWithPrevious > 5){
            let data = {
              clean: cleanData,
              moderate: moderateData,
              critical: criticalData
            }
            mapData.push(data)
            setFullData(mapData)
            setCleanData([])
            setModerateData([])
            setCriticalData([])
            pCoordinates = []
            mCoordinates = []
            cCoordinates = []
            pCoordinates.push([parseFloat(x.longitude), parseFloat(x.latitude)])
            if(sCheck == 'moderate'){
              mCoordinates.push([parseFloat(x.longitude), parseFloat(x.latitude)])
              allLng.push(x)
            }
            if(sCheck == 'critical'){
              cCoordinates.push([parseFloat(x.longitude), parseFloat(x.latitude)])
              allLng.push(x)
            }
            setCleanData(pCoordinates)
            setModerateData(mCoordinates)
            setCriticalData(cCoordinates)
            odcInfo.push(x)
            lastLatLong = x
          }
          else{
            pCoordinates.push([parseFloat(x.longitude), parseFloat(x.latitude)])
            if(sCheck == 'moderate'){
              mCoordinates.push([parseFloat(x.longitude), parseFloat(x.latitude)])
              allLng.push(x)
            }
            if(sCheck == 'critical'){
              cCoordinates.push([parseFloat(x.longitude), parseFloat(x.latitude)])
              allLng.push(x)
            }
            setCleanData(pCoordinates)
            setModerateData(mCoordinates)
            setCriticalData(cCoordinates)
            odcInfo.push(x)
            lastLatLong = x
          }
        })
        setLng(allLng)
        setODCinfoDetails(odcInfo)
        let newViewState = {
          latitude: lastLatLong.latitude,
          longitude: lastLatLong.longitude,
          zoom: 12
        }
        setViewState(newViewState)
        setLoad(false)
      })
      .catch((e) => {
        console.log(e);
        setLoad(false)
      });
    }
    
    setAllowMap(true)
  }


  const getSeverity = (msg) => {
    if(msg && msg.litters != undefined && msg.litters!=null && msg.litters.length > 0) {
      var criticalDataArr = msg.litters.find(x => x.severity === 'critical');
      if(criticalDataArr!=null) {
        return 'critical';
      } else {
        var moderateDataArr = msg.litters.find(x => x.severity === 'moderate');
        if(moderateDataArr!=null) {
          return 'moderate';
        } else {
          return 'clean';
        }
      }
    } else {
      return 'clean';
    }
  }

  const deg2Rad = (deg) => {
    return deg * Math.PI / 180;
  }

  const  pythagorasEquirectangular = (lat1, lon1, lat2, lon2) => {
    lat1 = deg2Rad(lat1);
    lat2 = deg2Rad(lat2);
    lon1 = deg2Rad(lon1);
    lon2 = deg2Rad(lon2);
    const R = 6371;
    const x = (lon2 - lon1) * Math.cos((lat1 + lat2) / 2);
    const y = (lat2 - lat1);
    const d = Math.sqrt(x * x + y * y) * R;
    return d;
  }
  


  const flyTo =(coordinates)=>{
    console.log([coordinates.lngLat.lng,  coordinates.lngLat.lat])
    map.flyTo({center: [Number(coordinates.lngLat.lng), Number( coordinates.lngLat.lat)]});
    if(props.odcValue != 'dicSensor'){
      const features = map.queryRenderedFeatures(coordinates.point);
      const clickedEvent = features && features.find(f => (f.layer.id.includes('moderate') ||f.layer.id.includes('critical')));
      if(clickedEvent){
        displayPopUpDetails(coordinates)
      }
    }
  }

  const displayPopUpDetails=(coordinates)=>{
    setShowPopup(false);
    setPopUpDetails(null);
    let mindif = props.odcValue == 'dicSensor' ? 0.01 : 5;
    let closest;
    let arrayClosest = [];
    getLng.length > 0 && getLng.map((x, i)=>{
      const dif = pythagorasEquirectangular(Number(coordinates.lngLat.lat), Number(coordinates.lngLat.lng), 
      Number(x.latitude), 
      Number(x.longitude))
      console.log("d", dif, mindif)
      if (dif < mindif) {
        //console.log("d", dif, mindif)
        closest = i;
        mindif = dif;
      }  
    }) 
    //console.log("closest", closest)
    if(closest != null && closest != undefined){
      //console.log("closer", getLng[closest])
      setPopUpDetails(getLng[closest])
      setShowPopup(true)
    } 
  }

  const getPopupInfo =()=>{
    if(props.odcValue == 'dicSensor'){
      //console.log("ndnnd", popUpDetails)
      /* let allowPopup = popUpDetails.fillLevel != null || popUpDetails.drainIntakeIndex != null
      || popUpDetails.averageRainFall !=null;
      if(allowPopup){
        return(
          <Popup longitude={parseFloat(popUpDetails.longitude)} latitude={parseFloat(popUpDetails.latitude)}
          anchor="center"
          closeOnClick={false}
          
          onClose={() => setShowPopup(false)}>
            <Card sx={{width: `320px`}}>    
              <CardContent >    
                <List
                  sx={{
                    overflowY: "auto",
                    margin: 0,
                    padding: 0,
                    listStyle: "none",
                    height: "100%",
                    '&::-webkit-scrollbar': {
                      width: '0.4em'
                    },
                    '&::-webkit-scrollbar-track': {
                      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: 'rgba(0,0,0,.1)',
                      outline: '1px solid slategrey'
                    }
                  }}
                >

                  <ListItem
                    //style={{paddingLeft: `10px`, margin: `-12px 0px` }} 
                    disableGutters>
                    {popUpDetails.fillLevel != null  &&(
                      <Grid container spacing={1} >
                        <Grid item xs={6} sm={6} md={6}>
                          <Typography variant="body2" style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}}>
                            {`FILL LEVEL :`}</Typography>
                        
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                        <Typography variant="body2" >
                            {popUpDetails.fillLevel}</Typography>
                        </Grid>
                      </Grid>
                    )}
                  </ListItem>
                
                  <ListItem
                    //style={{paddingLeft: `10px`, margin: `-12px 0px` }} 
                    disableGutters>
                    {popUpDetails.averageRainFall != null &&(
                      <Grid container spacing={1} >
                        <Grid item xs={6} sm={6} md={6}>
                          <Typography variant="body2" style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>
                            {`AVERAGE RAINFALL :`}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                          <Typography variant="body2">
                            {popUpDetails.averageRainFall}</Typography>
                        </Grid>
                      </Grid>
                    )}
                  </ListItem>

                  <ListItem
                  // style={{paddingLeft: `10px`, margin: `-12px 0px` }} 
                    disableGutters>
                    {popUpDetails.drainIntakeIndex != null &&(
                      <Grid container spacing={1} >
                        <Grid item xs={6} sm={6} md={6}>
                          <Typography variant="body2" style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>
                            {`DRAIN INTAKE :`}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                        <Typography variant="body2" >
                            {popUpDetails.drainIntakeIndex}</Typography>
                        </Grid>
                      </Grid>
                    )}
                  </ListItem>

                  {popUpDetails.type == "FLOODING" &&(
                    <>
                    <ListItem
                  // style={{paddingLeft: `10px`, margin: `-12px 0px` }} 
                    disableGutters>
                    {popUpDetails.sensorId != null &&(
                      <Grid container spacing={1} >
                        <Grid item xs={6} sm={6} md={6}>
                          <Typography variant="body2" style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>
                            {`SENSOR ID: :`}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                        <Typography variant="body2" >
                            {popUpDetails.sensorId}</Typography>
                        </Grid>
                      </Grid>
                    )}
                  </ListItem>

                  <ListItem
                  // style={{paddingLeft: `10px`, margin: `-12px 0px` }} 
                    disableGutters>
                    {popUpDetails.floodingSeverity != null &&(
                      <Grid container spacing={1} >
                        <Grid item xs={6} sm={6} md={6}>
                          <Typography variant="body2" style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>
                            {`FLOODING :`}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                        <Typography variant="body2" >
                            {popUpDetails.floodingSeverity}</Typography>
                        </Grid>
                      </Grid>
                    )}
                  </ListItem>

                  <ListItem
                  // style={{paddingLeft: `10px`, margin: `-12px 0px` }} 
                    disableGutters>
                    {popUpDetails.longitude != null && popUpDetails.latitude != null &&(
                      <Grid container spacing={1} >
                        <Grid item xs={6} sm={6} md={6}>
                          <Typography variant="body2" style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>
                            {`LOCATION :`}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                        <Typography variant="body2" >
                            {popUpDetails.longitude}<br />  {popUpDetails.latitude}</Typography>
                        </Grid>
                      </Grid>
                    )}
                  </ListItem>
                    </>
                  )}
                  

                </List>
              </CardContent>      
              <CardActions>
                <Button size="small" variant='contained'>Deploy</Button>
              </CardActions>
              
            </Card>
        </Popup>
        );
      }*/
      return(
        <Popup longitude={parseFloat(popUpDetails.longitude)} latitude={parseFloat(popUpDetails.latitude)}
        anchor="center"
        closeOnClick={false}
        
        onClose={() => setShowPopup(false)}>
          <Card sx={{width: `320px`}}> 
              {popUpDetails && popUpDetails.image &&(
                <CardMedia
                sx={{ height: 200 }}
                image={popUpDetails.image}
              />  
              )}
              
            <CardContent >    
              <List
                sx={{
                  overflowY: "auto",
                  margin: 0,
                  padding: 0,
                  listStyle: "none",
                  height: "100%",
                  '&::-webkit-scrollbar': {
                    width: '0.4em'
                  },
                  '&::-webkit-scrollbar-track': {
                    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: 'rgba(0,0,0,.1)',
                    outline: '1px solid slategrey'
                  }
                }}
              >

                <ListItem
                  //style={{paddingLeft: `10px`, margin: `-12px 0px` }} 
                  disableGutters>
                  {popUpDetails.address &&(
                    <Grid container spacing={1} >
                      <Grid item xs={6} sm={6} md={6}>
                        <Typography variant="body2" style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}}>
                          {`ADDRESS :`}</Typography>
                      
                      </Grid>
                      <Grid item xs={6} sm={6} md={6}>
                      <Typography variant="body2" >
                          {popUpDetails.address}</Typography>
                      </Grid>
                    </Grid>
                  )}
                </ListItem>
              
                <ListItem
                  //style={{paddingLeft: `10px`, margin: `-12px 0px` }} 
                  disableGutters>
                  {popUpDetails.deviceId &&(
                    <Grid container spacing={1} >
                      <Grid item xs={6} sm={6} md={6}>
                        <Typography variant="body2" style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>
                          {`Device ID :`}</Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6}>
                        <Typography variant="body2">
                          {popUpDetails.deviceId}</Typography>
                      </Grid>
                    </Grid>
                  )}
                </ListItem>

                <ListItem
                // style={{paddingLeft: `10px`, margin: `-12px 0px` }} 
                  disableGutters>
                  {popUpDetails.deviceStatus &&(
                    <Grid container spacing={1} >
                      <Grid item xs={6} sm={6} md={6}>
                        <Typography variant="body2" style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>
                          {`DEVICE STATUS :`}</Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6}>
                      <Typography variant="body2" >
                          {popUpDetails.deviceStatus}</Typography>
                      </Grid>
                    </Grid>
                  )}
                </ListItem>

                <ListItem
                // style={{paddingLeft: `10px`, margin: `-12px 0px` }} 
                  disableGutters>
                  {popUpDetails.level &&(
                    <Grid container spacing={1} >
                      <Grid item xs={6} sm={6} md={6}>
                        <Typography variant="body2" style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>
                          {`LEVEL :`}</Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6}>
                      <Typography variant="body2" >
                          {popUpDetails.level + `%`}</Typography>
                      </Grid>
                    </Grid>
                  )}
                </ListItem>

              </List>
            </CardContent>      
            <CardActions>
              <Button size="small" variant='contained'>Deploy</Button>
            </CardActions>
            
          </Card>
      </Popup>
      );
    }
    else{
      let allowPopup = popUpDetails.displayAssets != null || popUpDetails.image != null
      || popUpDetails.street !=null || popUpDetails.lapses != null;
      if(allowPopup){
        return(
          <Popup longitude={parseFloat(popUpDetails.longitude)} latitude={parseFloat(popUpDetails.latitude)}
          anchor="center"
          closeOnClick={false}
          maxWidth={`300px`}
          onClose={() => setShowPopup(false)}>
            <Card sx={{height: `350px`}}>
              <List
                sx={{
                  overflowY: "auto",
                  margin: 0,
                  padding: 0,
                  listStyle: "none",
                  height: "100%",
                  '&::-webkit-scrollbar': {
                    width: '0.4em'
                  },
                  '&::-webkit-scrollbar-track': {
                    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: 'rgba(0,0,0,.1)',
                    outline: '1px solid slategrey'
                  }
                }}
              >
                <ListItem
                style={{paddingLeft: `10px`, margin: `-12px 0px` }} 
                disableGutters>
                  <Grid container spacing={1} >
                    <Grid item xs={12} sm={12} md={12}>
                      <img src={`https://ctm-images.s3-ap-southeast-1.amazonaws.com/${popUpDetails.image}`} 
                      width={`100%`} height={`250px`} />
                    </Grid>
                  </Grid>
                </ListItem>

                 <ListItem
                  style={{paddingLeft: `10px`, margin: `-12px 0px` }} 
                  disableGutters>
                  {popUpDetails.street != null  &&(
                    <Grid container spacing={1} >
                      <Grid item xs={4} sm={4} md={4}>
                        <ListItemText primary={
                          <Typography variant="body2" style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: `9px` }}>
                          {`STREETS :`}</Typography>
                          } 
                        />
                      </Grid>
                      <Grid item xs={8} sm={8} md={8}>
                        <ListItemText secondary={
                        <Typography variant="body2" style={{fontSize: `9px`}}>
                          {popUpDetails.street}</Typography>}/>
                      </Grid>
                    </Grid>
                  )}
                </ListItem>
              
                <ListItem
                  style={{paddingLeft: `10px`, margin: `-12px 0px` }} 
                  disableGutters>
                  {popUpDetails.displayAssets != null &&(
                    <Grid container spacing={1} >
                      <Grid item xs={4} sm={4} md={4}>
                        <ListItemText primary={
                          <Typography variant="body2" style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: `9px` }}>
                          {`ASSETS :`}</Typography>
                          } 
                        />
                      </Grid>
                      <Grid item xs={8} sm={8} md={8}>
                        <ListItemText secondary={
                        <Typography variant="body2" style={{fontSize: `9px`}}>
                          {popUpDetails.displayAssets}</Typography>}/>
                      </Grid>
                    </Grid>
                  )}
                </ListItem>

                <ListItem
                  style={{paddingLeft: `10px`, margin: `-12px 0px` }} 
                  disableGutters>
                  {popUpDetails.lapses != null &&(
                    <Grid container spacing={1} >
                      <Grid item xs={4} sm={4} md={4}>
                        <ListItemText primary={
                          <Typography variant="body2" style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: `9px` }}>
                          {`LAPSES :`}</Typography>
                          } 
                        />
                      </Grid>
                      <Grid item xs={8} sm={8} md={8}>
                        <ListItemText secondary={
                        <Typography variant="body2" style={{fontSize: `9px`}}>
                          {popUpDetails.lapses}</Typography>}/>
                      </Grid>
                    </Grid>
                  )}
                </ListItem>
            
              </List>
            </Card>
        </Popup>
        );
      }
      else{
        return null;
      }
    }
      
    
  }

  const getDicPopUp=(x)=>{
    setPopUpDetails(x)
    setShowPopup(true)
  }

  


  let onWsMessageReceived = (msg) => {
    console.log(msg)
    if(!load && allowMap && props.odcValue != 'dicSensor'){
      let checkSeverity = getSeverity(msg);        
      let sCheck = checkSeverity == null ? 'clean' : checkSeverity;
      if(props.odcValue == msg.type){
        if(msg.durationInMinutesWithPrevious > 5){
          let data = [...fullData]
          let newLine = {
            clean: cleanData,
            moderate: moderateData,
            critical: criticalData
          }
          data.push(newLine)
          setFullData(data)
          setCleanData([])
          setModerateData([])
          setCriticalData([])
          let pCoordinates = []
          let mCoordinates = []
          let cCoordinates = []
          let allLng = [...getLng];
          pCoordinates.push([parseFloat(msg.longitude), parseFloat(msg.latitude)])
          if(sCheck == 'moderate'){
            mCoordinates.push([parseFloat(msg.longitude), parseFloat(msg.latitude)])
            allLng.push(msg)
          }
          if(sCheck == 'critical'){
            cCoordinates.push([parseFloat(msg.longitude), parseFloat(msg.latitude)])
            allLng.push(msg)
          }
          setCleanData(pCoordinates)
          setModerateData(mCoordinates)
          setCriticalData(cCoordinates)
        }
        else{         
            setCleanData(prev=>[...prev, [parseFloat(msg.longitude), parseFloat(msg.latitude)]])
            if(sCheck == 'moderate'){
              //moderateElement.push([parseFloat(msg.longitude), parseFloat(msg.latitude)])
              setModerateData(prev=>[...prev, [parseFloat(msg.longitude), parseFloat(msg.latitude)]])
              setLng((prev)=> [...prev, msg])
            }
            if(sCheck == 'critical'){
              //criticalElement.push([parseFloat(msg.longitude), parseFloat(msg.latitude)])
              setCriticalData(prev=>[...prev, [parseFloat(msg.longitude), parseFloat(msg.latitude)]])
              setLng((prev)=> [...prev, msg])
            }
          
        }        
      }   

    }   
  }


  const getPinLastLocation =()=>{
    if(!load && allowMap && (fullData.length > 0 || cleanData.length > 0)){
      let lastLocation;
      if(cleanData.length > 0){
        lastLocation  = cleanData.length > 0 && cleanData.pop()
      }
      else{
        lastLocation  =  fullData && fullData.length > 0 && fullData[fullData.length - 1] && fullData[fullData.length - 1].clean &&
        fullData[fullData.length - 1].clean.pop()
      }
      console.log('getPinLastLocation', lastLocation)
      
      if(lastLocation){
        return(
          <Marker
          key={'marker'}
          latitude={lastLocation[1]}
          longitude={lastLocation[0]}
          >
            <div>
              <img src={MarkerIcon} className="mrt-marker"  height={40}/>
            </div>        
            {/* <PinDropIcon fontSize="large"  color='error'/> */}
          </Marker>
        )
      }
    }
  }


  const getMapDetails=()=>{
    console.log("getMapDetails1")
      if(!load &&  allowMap){
          return(
            <>
            {fullData.length > 0 &&  fullData.map((x, i)=>{
                return(
                    <>
                        <Source key={`source-clean${i}`} 
                          type="geojson" 
                          data={{ type: 'Feature', geometry: { type: 'LineString', coordinates: x.clean } }}
                        >
                            <Layer
                              id={`line-layer-clean${i}`}
                              type="line"
                              paint={{
                                'line-color': cleanColor,
                                'line-width': 10,
                              }}
                            //  layout={{ "visibility": "visible", "interactive": true }}
                            interactive={true}
                            />
                        </Source>
      
                        <Source key={`source-moderate${i}`} 
                          type="geojson" 
                          data={{ type: 'Feature', geometry: { type: 'LineString', coordinates: x.moderate } }}
                        >
                            <Layer
                              id={`line-layer-moderate${i}`}
                              type="circle"
                              paint={{
                                'circle-color': moderateColor,
                                'circle-radius': 5,
                              }}
                            //  layout={{ "visibility": "visible", "interactive": true }}
                            interactive={true}
                            />
                        </Source>
      
                        <Source key={`source-critical${i}`} 
                          type="geojson" 
                          data={{ type: 'Feature', geometry: { type: 'LineString', coordinates: x.critical } }}
                        >
                            <Layer
                              id={`line-layer-critical${i}`}
                              type="circle"
                              paint={{
                                'circle-color': criticalColor,
                                'circle-radius': 5,
                              }}
                            //  layout={{ "visibility": "visible", "interactive": true }}
                            interactive={true}
                            />
                        </Source>
                    </>
                )
              })}
            {cleanData.length > 0 && (
              <Source key={`source-clean${'realtime'}`} 
              type="geojson" 
              data={{ type: 'Feature', geometry: { type: 'LineString', coordinates: cleanData } }}
            >
                <Layer
                  id={`line-layer-clean${'realtime'}`}
                  type="line"
                  paint={{
                    'line-color': cleanColor,
                    'line-width': 10,
                  }}
                //  layout={{ "visibility": "visible", "interactive": true }}
                interactive={true}
                />
            </Source>
            )}

            {moderateData.length > 0 &&(
              <Source key={`source-moderate${'realtime'}`} 
              type="geojson" 
              data={{ type: 'Feature', geometry: { type: 'LineString', coordinates: moderateData} }}
            >
                <Layer
                  id={`line-layer-moderate${'realtime'}`}
                  type="circle"
                  paint={{
                    'circle-color': moderateColor,
                    'circle-radius': 5,
                  }}
                //  layout={{ "visibility": "visible", "interactive": true }}
                interactive={true}
                />
            </Source>
            )}

            {criticalData.length > 0 &&(
              <Source key={`source-critical${'realtime'}`} 
              type="geojson" 
              data={{ type: 'Feature', geometry: { type: 'LineString', coordinates: criticalData } }}
            >
                <Layer
                  id={`line-layer-critical${'realtime'}`}
                  type="circle"
                  paint={{
                    'circle-color': criticalColor,
                    'circle-radius': 5,
                  }}
                //  layout={{ "visibility": "visible", "interactive": true }}
                interactive={true}
                />
            </Source>
            )}
              
              {getPinLastLocation()}
            </>
          )
      }
   
  }

  const getDicSensorDetails=()=>{
    return(
      <>
     {/*  {!load && dic.length > 0 && dic.map((x, i) =>{
        return(
            <>
             <Source key={`dic-${i}`} 
                    type="geojson" 
                    data={{ type: 'Feature', geometry: { type: 'LineString', coordinates: x.coordinates } }}
                  >
                      <Layer
                        id={`line-layer-dic${i}`}
                        type="line"
                        paint={{
                          'line-color': cleanColor,
                          'line-width': 10,
                        }}
                      interactive={true}
                      />
                  </Source>
                  {x.moderateLine.map((moderate, index)=>{
                    return(
                      <>
                      <Source key={`${i}-dic-moderate-${index}`} 
                        type="geojson" 
                        data={{ type: 'Feature', geometry: { type: 'LineString', coordinates: moderate } }}
                      >
                          <Layer
                            id={`${i}-line-layer-dic-moderate${index}`}
                            type="line"
                            paint={{
                              'line-color': moderateColor,
                              'line-width': 10,
                            }}
                          interactive={true}
                          />
                        </Source>
                      </>
                    )
                  })}
                  {x.criticalLine.map((critical, index)=>{
                    return(
                      <>
                      <Source key={`${i}-dic-critical-${index}`} 
                        type="geojson" 
                        data={{ type: 'Feature', geometry: { type: 'LineString', coordinates: critical } }}
                      >
                          <Layer
                            id={`${i}-line-layer-dic-critical${index}`}
                            type="line"
                            paint={{
                              'line-color': criticalColor,
                              'line-width': 10,
                            }}
                          interactive={true}
                          />
                        </Source>
                      </>
                    )
                  })}
                  {x.data.map((info, indexValue)=>{
                    return(
                      <>
                      <Marker
                        key={`${i}-marker-${indexValue}`}
                        latitude={info.latitude}
                        longitude={info.longitude}
                        >
                          <CustomIcon data={info}/>
                        </Marker> 
                      </>
                    )
                  })}
            </>
        )
      })} */}
      {!load && dic.length > 0 && dic.map((x, i) =>{
         return(
          <>
          <Marker
            key={`${i}-marker-${i}`}
            latitude={x.latitude}
            longitude={x.longitude}
            onClick={()=> getDicPopUp(x)}
            >
              <CustomIcon data={x}/>
            </Marker> 
          </>
        )
      })}
      </>
    )
  }


  const getSmartArm=()=>{
    return(
      <>
      {!load && combi.length > 0 && combi.map((x, i) =>{
        return(
          <>
              <Source key={`source-combi-fullData${i}`} 
                type="geojson" 
                data={{ type: 'Feature', geometry: { type: 'LineString', coordinates: x.fullData } }}
              >
                  <Layer
                    id={`line-layer-combi-fullData${i}`}
                    type="line"
                    paint={{
                      'line-color': blueColor,
                      'line-width': 10,
                    }}
                  //  layout={{ "visibility": "visible", "interactive": true }}
                  interactive={true}
                  />
              </Source>

              <Source key={`source-combi-clean${i}`} 
                type="geojson" 
                data={{ type: 'Feature', geometry: { type: 'LineString', coordinates: x.clean } }}
              >
                  <Layer
                    id={`line-layer-combi-clean${i}`}
                    type="circle"
                    paint={{
                      'circle-color': cleanColor,
                      'circle-radius': 5,
                    }}
                  //  layout={{ "visibility": "visible", "interactive": true }}
                  interactive={true}
                  />
              </Source>

              {/* <Source key={`source-combi-moderate${i}`} 
                type="geojson" 
                data={{ type: 'Feature', geometry: { type: 'LineString', coordinates: x.moderate } }}
              >
                  <Layer
                    id={`line-layer-combi-moderate${i}`}
                    type="circle"
                    paint={{
                      'circle-color': moderateColor,
                      'circle-radius': 5,
                    }}
                  //  layout={{ "visibility": "visible", "interactive": true }}
                  interactive={true}
                  />
              </Source>
 */}
              <Source key={`source-combi-critical${i}`} 
                type="geojson" 
                data={{ type: 'Feature', geometry: { type: 'LineString', coordinates: x.critical } }}
              >
                  <Layer
                    id={`line-layer-combi-critical${i}`}
                    type="circle"
                    paint={{
                      'circle-color': criticalColor,
                      'circle-radius': 5,
                    }}
                  interactive={true}
                  />
              </Source>
          </>
      )
      })}
      </>
    )
  }

  
 
  return (
    <>
      <Grid container>    
        <Grid item xs={12} md={12} sm={12}>
                <Map
                 // key={mapKey}
                  initialViewState={viewState}
                  ref={mapRef}
                  mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
                  style={{width: `100%`, height: `70vh`}}
                  mapStyle={MAP_STYLE_URL}
                  onLoad={(event) => { 
                    console.log("ww", event)
                    setMap(event.target); 
                  }}
                  onClick={flyTo}
                  >
                    {props.odcValue != 'dicSensor' && props.odcValue != 'smartCombi' &&(
                       <>
                        {getMapDetails()}
                       </>
                    )}    
                     {props.odcValue == 'dicSensor' && (
                       <>
                        {getDicSensorDetails()}
                       </>
                    )}   
                    {props.odcValue == 'smartCombi' && (
                       <>
                        {getSmartArm()}
                       </>
                    )}   
                    {!load &&  showPopup && popUpDetails != null &&(
                      getPopupInfo()
                    )}              
                  <Loading loading={load} type={`glass`} backDrop={true}/>
                  <NavigationControl anchor="bottom-right" />
                  <FullscreenControl />  
                  <GeolocateControl /> 
                  <ScaleControl />
                </Map>
        </Grid>           
      </Grid> 
          <MapWebSocket onChange={onWsMessageReceived}/>
    </> 
  );
}

export default ComponentOne



{/* {allowMap && poly.length >0 && poly.map((polyline, index) => {
   
        <Source key={index} type="geojson" data={{ type: 'Feature', geometry: { type: 'LineString', coordinates: polyline.coordinates } }}>
        <Layer
          id={`line-layer-${index}`}
          type="line"
          paint={{
            'line-color': polyline.color,
            'line-width': polyline.width,
          }}
        />
      </Source>
    })} */}


    // setShowPopup(false)
    // console.log("coordinates", coordinates.lngLat.lng, coordinates.lngLat.lat)
    // console.log( typeof coordinates.lngLat.lng)
    // ODCinfoDetails.length > 0 && ODCinfoDetails.map((x, i)=>{
    //   console.log(x.latitude)
    // })
    //parseFloat(x.longitude), parseFloat(x.latitude)
    /* ODCinfoDetails.length > 0 && ODCinfoDetails.map((x, i)=>{
      console.log("jdjjd")
        if(Number(x.latitude) == coordinates.lngLat.lat && Number(x.longitude) == coordinates.lngLat.lng){
          console.log("sshshsh")
          setPopUpDetails(x)
          setShowPopup(true)
          return;
        }
    }) */

    // let data = {
    //   latitude: coordinates.lngLat.lat,
    //   longitude: coordinates.lngLat.lng
    // }
    // setPopUpDetails(data)
    // setShowPopup(true)
    // let findDetails = ODCinfoDetails.length > 0 &&  
    // ODCinfoDetails.find(x => 
    // (Number(x.latitude) === Number(coordinates.lngLat.lat) ||
    // Number(x.longitude) === Number(coordinates.lngLat.lng) ));
    // console.log(findDetails)