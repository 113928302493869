import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";

export default function CardCustomHeader(props) {

    return(
        <Grid container >
            <Grid item xs={12} style={{padding: `5px`}}>
                <Typography variant={props.varient ? props.varient : "h6"}
                align={props.alignment ? props.alignment : `left` }>{props.title}</Typography>
            </Grid>
        </Grid>
    );
}