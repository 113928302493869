/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_CoTfuUqSR",
    "aws_user_pools_web_client_id": "42uc3fg6umtfjjro7poti1k13q",
    "oauth": {}
};


export default awsmobile;
