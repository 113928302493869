import React, { useEffect } from 'react'
import { ResponsivePie } from '@nivo/pie'
import DataServices from '../../Services/DataServices';
import ChartWebSocket from '../../WebSockets/ChartWebSocket';


export const AssetsChart = () => {
    const [response, setResponse] = React.useState([]);
    const [chartData, setChartData] = React.useState([]);
    let onWsAssetsMessageReceived = (msg) => {
        console.log('MSG FROM CHARTWEBSOCKET assetschart',JSON.stringify(msg.litterAssets));
        setResponse([]);
        setData(msg.litterAssets);
    }
    const setData = (data) => {
        data.map((ac) => {
          setResponse(response => [...response,{id: ac.name,label: ac.name, value: ac.value}]);  
        })
      }
      const retrieveData = () => {
        DataServices.getAssetCount()
          .then((response) => {
            //console.log('response from getAssetCount: '+JSON.stringify(response.data.data));
            setData(response.data.data);
          })
          .catch((e) => {
            console.log(e);
          });
      };
    useEffect(() => {
        retrieveData();
    }, [])

    useEffect(() => {
        //console.log("response", response)
        const uniqueArray = [...new Set(response.map(item => item.id))].map(id => {
            return response.find(item => item.id === id);
        });
        setChartData(uniqueArray)
    }, [response])

    const chartInfo  = chartData.length > 0 && chartData.sort((a, b) => b.value - a.value).slice(0, 5);
    if(chartInfo){
        return (
            <div style={{ height: 90 }}>
            <ResponsivePie
                data={chartInfo}
                margin={{  right: 0, bottom: 5, left: 0 }} //top: 20,
                innerRadius={0.5}
                padAngle={0.7}
                cornerRadius={3}
                colors={{ scheme: 'category10' }}
                borderWidth={1}
                borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
                enableRadialLabels={false}
                enableArcLabels={false}
                enableArcLinkLabels={false}
                radialLabelsLinkOffset={-24}
                radialLabelsSkipAngle={0}
                radialLabelsTextColor="white"
                radialLabelsLinkColor={{ from: 'color' }}
                sliceLabelsSkipAngle={10}
                sliceLabelsTextColor="black"
            
                enableSliceLabels={false}
                theme={{
                textColor: 'white',
                tooltip: {
                    container: {
                      color: 'black',
                      background: 'white',
                    },
                  },
                }}
                defs={[
                    {
                        id: 'dots',
                        type: 'patternDots',
                        background: 'inherit',
                        color: 'rgba(255, 255, 255, 0.3)',
                        size: 4,
                        padding: 1,
                        stagger: true
                    },
                    {
                        id: 'lines',
                        type: 'patternLines',
                        background: 'inherit',
                        color: 'rgba(255, 255, 255, 0.3)',
                        rotation: -45,
                        lineWidth: 6,
                        spacing: 10
                    }
                ]}
                fill={[
                    {
                        match: {
                            id: 'ruby'
                        },
                        id: 'dots'
                    },
                    {
                        match: {
                            id: 'c'
                        },
                        id: 'dots'
                    },
                    {
                        match: {
                            id: 'go'
                        },
                        id: 'dots'
                    },
                    {
                        match: {
                            id: 'python'
                        },
                        id: 'dots'
                    },
                    {
                        match: {
                            id: 'scala'
                        },
                        id: 'lines'
                    },
                    {
                        match: {
                            id: 'lisp'
                        },
                        id: 'lines'
                    },
                    {
                        match: {
                            id: 'elixir'
                        },
                        id: 'lines'
                    },
                    {
                        match: {
                            id: 'javascript'
                        },
                        id: 'lines'
                    }
                ]}
                legends={[
                    {
                        anchor: 'bottom-left',
                        direction: 'column',
                        justify: false,
                        translateX: 0,
                        translateY: 0,
                        itemWidth: 10,
                        itemHeight: 5,
                        itemsSpacing: 10,
                        symbolSize:12,
                        itemDirection: 'left-to-right',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemTextColor: '#000'
                                }
                            }
                        ],
                        //data: chartData.sort((a, b) => b.value - a.value).slice(0, 5),
                    }
                ]}
            />
            <ChartWebSocket onChange={onWsAssetsMessageReceived} />
        </div>
        );
    }
    else{
        return null;
    }        
}
