import React, { useState, useEffect, useMemo, useRef } from "react";
import Map, {
  Layer,
  Marker,
  Source,
  Popup,
  ScaleControl,
  FullscreenControl,
  NavigationControl,
  GeolocateControl,
} from "react-map-gl";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import DataServices from "../../Services/DataServices";
import MarkerIcon from "../../Assets/pin.png";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { Button, CardActionArea, CardActions } from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Loading from "../../Controller/LoadingController/Loading";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import CustomIcon from "./CustomIcon";
import UrlConfigs from "../../configs/UrlConfig";

const MAP_STYLE_URL = UrlConfigs.MAP_STYLE_URL;
const MAPBOX_ACCESS_TOKEN = UrlConfigs.MAPBOX_ACCESS_TOKEN;

const initialMapViewState = {
  latitude: 1.3179623906210907,
  longitude: 103.80952185215926,
  zoom: 12,
};

const ViewDicInMap = ({ loading, dicData }) => {
  const [viewState, setViewState] = useState(initialMapViewState);
  const [map, setMap] = useState(null);
  const [showPopup, setShowPopup] = useState(true);
  const [popUpDetails, setPopUpDetails] = useState(null);

  useEffect(() => {
    if (popUpDetails) {
      setShowPopup(true);
    }
  }, [popUpDetails]);

  useEffect(() => {
    if (!showPopup) {
      setPopUpDetails(null);
    }
  }, [showPopup]);

  const viewPopUp = () => {
    if (popUpDetails) {
      return (
        <Popup
          longitude={parseFloat(popUpDetails.longitude)}
          latitude={parseFloat(popUpDetails.latitude)}
          anchor="center"
          closeOnClick={false}
          onClose={() => setShowPopup(false)}>
          <Card sx={{ width: `320px` }}>
            {popUpDetails.image && (
              <CardMedia sx={{ height: 200 }} image={popUpDetails.image} />
            )}

            <CardContent>
              <List
                sx={{
                  overflowY: "auto",
                  margin: 0,
                  padding: 0,
                  listStyle: "none",
                  height: "100%",
                  "&::-webkit-scrollbar": {
                    width: "0.4em",
                  },
                  "&::-webkit-scrollbar-track": {
                    boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                    webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "rgba(0,0,0,.1)",
                    outline: "1px solid slategrey",
                  },
                }}>
                <ListItem
                  //style={{paddingLeft: `10px`, margin: `-12px 0px` }}
                  disableGutters>
                  {popUpDetails.address && (
                    <Grid container spacing={1}>
                      <Grid item xs={6} sm={6} md={6}>
                        <Typography
                          variant="body2"
                          style={{
                            whiteSpace: "pre-wrap",
                            overflowWrap: "break-word",
                          }}>
                          {`ADDRESS :`}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6}>
                        <Typography variant="body2">
                          {popUpDetails.address}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </ListItem>

                <ListItem
                  //style={{paddingLeft: `10px`, margin: `-12px 0px` }}
                  disableGutters>
                  {popUpDetails.deviceId && (
                    <Grid container spacing={1}>
                      <Grid item xs={6} sm={6} md={6}>
                        <Typography
                          variant="body2"
                          style={{
                            whiteSpace: "pre-wrap",
                            overflowWrap: "break-word",
                          }}>
                          {`Device ID :`}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6}>
                        <Typography variant="body2">
                          {popUpDetails.deviceId}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </ListItem>

                <ListItem
                  // style={{paddingLeft: `10px`, margin: `-12px 0px` }}
                  disableGutters>
                  {popUpDetails.deviceStatus && (
                    <Grid container spacing={1}>
                      <Grid item xs={6} sm={6} md={6}>
                        <Typography
                          variant="body2"
                          style={{
                            whiteSpace: "pre-wrap",
                            overflowWrap: "break-word",
                          }}>
                          {`DEVICE STATUS :`}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6}>
                        <Typography variant="body2">
                          {popUpDetails.deviceStatus}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </ListItem>

                <ListItem
                  // style={{paddingLeft: `10px`, margin: `-12px 0px` }}
                  disableGutters>
                  {popUpDetails.level && (
                    <Grid container spacing={1}>
                      <Grid item xs={6} sm={6} md={6}>
                        <Typography
                          variant="body2"
                          style={{
                            whiteSpace: "pre-wrap",
                            overflowWrap: "break-word",
                          }}>
                          {`LEVEL :`}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6}>
                        <Typography variant="body2">
                          {popUpDetails.level + `%`}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </ListItem>
              </List>
            </CardContent>
            <CardActions>
              <Button size="small" variant="contained">
                Deploy
              </Button>
            </CardActions>
          </Card>
        </Popup>
      );
    }
  };

  const getDicPopUp = (x) => {
    setPopUpDetails(x);
  };

  const getDicSensorDetails = () => {
    return (
      <>
        {dicData.length > 0 &&
          dicData.map((x, i) => (
            <>
              <Marker
                key={`${i}-marker-${i}`}
                latitude={x.latitude}
                longitude={x.longitude}
                onClick={() => getDicPopUp(x)}>
                <CustomIcon data={x} zoom={viewState.zoom} />
              </Marker>
            </>
          ))}
      </>
    );
  };

  const flyTo = (coordinates) => {
    console.log([coordinates.lngLat.lng, coordinates.lngLat.lat]);
    map.flyTo({
      center: [Number(coordinates.lngLat.lng), Number(coordinates.lngLat.lat)],
    });
  };

  return (
    <Map
      initialViewState={viewState}
      mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
      onMove={(evt) => setViewState(evt?.viewState)}
      style={{ width: `100%`, height: `80vh` }}
      mapStyle={MAP_STYLE_URL}
      onLoad={(event) => setMap(event.target)}
      onClick={flyTo}>
      {getDicSensorDetails()}
      {showPopup && viewPopUp()}
      <Loading loading={loading} type={`glass`} backDrop={true} />
      <NavigationControl anchor="bottom-right" />
      <FullscreenControl />
      <GeolocateControl />
      <ScaleControl />
    </Map>
  );
};

export default ViewDicInMap;
