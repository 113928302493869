import { MagnifyingGlass, ThreeDots, 
    ProgressBar, Bars } from  'react-loader-spinner';
import Backdrop from '@mui/material/Backdrop';
import { useTheme } from '@mui/material';
import { Grid } from '@mui/material';

function Loading(props){
    const theme = useTheme();
//    console.log("prr", props)
    const getPropTypeLoading=()=>{
        if(props.type == 'glass'){
            return(
                <MagnifyingGlass
                    visible={props.loading}
                    height="80"
                    width="80"
                    ariaLabel="MagnifyingGlass-loading"
                    wrapperStyle={{}}
                    wrapperClass="MagnifyingGlass-wrapper"
                    glassColor = '#c0efff'
                    color = '#e15b64'
                    />
            );
        }
        else if(props.type == 'dots'){
            //console.log('dots')
            return(
                <ThreeDots 
                height="80" 
                width="80" 
                //radius="9"
                color={theme.palette.primary.light}
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={props.loading}
                />
              
            );
        }
        else if(props.type == 'progress'){
            return(
                <ProgressBar
                height="80"
                //width="200"
                ariaLabel="progress-bar-loading"
                wrapperStyle={{}}
                wrapperClass="progress-bar-wrapper"
                borderColor = '#F4442E'
                barColor = {theme.palette.primary.light}
                visible={props.loading}
            />
            );
        }
        else{
            return(
                <Bars
                    height="80"
                    width="80"
                    color={theme.palette.primary.light}
                    ariaLabel="bars-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={props.loading}
                    />
            )
        }
    }

    const allowBackDrop =()=>{
        if(props && props.backDrop){
            return(
                <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1,
                position: 'absolute'  }}
                open={props.loading}
              >
                {getPropTypeLoading()}
              </Backdrop>
            )
        }
        else{
            return getPropTypeLoading();
        }
    }

    return(
      <>
        <Grid container>
            <Grid item xs={12} sm={12} md={12} style={{display: `flex`, alignItems: `center`, justifyContent: `center`}}>
                {props.loading && allowBackDrop()}
            </Grid>
        </Grid>        
      </>
    )
}

export default Loading;