import React, { useEffect, useState } from 'react'
import DataServices from '../../Services/DataServices';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Grid, Card, CardContent } from "@mui/material";
import Loading from '../../Controller/LoadingController/Loading';


export const StreetDataTable = () => {
    const [response, setResponse] = useState([]);
    const [load, setLoad]= useState(false)


    const retrieveData = async() => {
        setLoad(true)
        await DataServices.getStreetSeverity()
          .then((response) => {
            //console.log('response from getStreetSeverity: '+JSON.stringify(response.data.data));
            setResponse(response.data.data);
          })
          .catch((e) => {
            console.log(e);
          });
          setLoad(false)
    };

    useEffect(() => {
        retrieveData();
    }, [])


    return(
      <>
      <List
        sx={{
            overflowY: "none",
            margin: 0,
            padding: 0,
            listStyle: "none",
            maxHeight: 200,
            /* '&::-webkit-scrollbar': {
              width: '0.4em'
            },
            '&::-webkit-scrollbar-track': {
              boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(0,0,0,.1)',
              outline: '1px solid slategrey'
            } */
            
          }}>
    {response.length > 0 && response.map((value, i) => {
      {if(i < 5){
        return(
          <ListItem
          key={i}
          style={{paddingLeft: `10px`, margin: `-12px 0px` }} //paddingLeft: `10px`, marginTop: `-20px`, marginBottom: `-20px`
          disableGutters
        >
        <Grid container spacing={1}>
          <Grid item xs={10} sm={10} md={10}>
            <ListItemText primary={
              <Typography variant="body2" style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: `12px` }}>
              {value.name}</Typography>
              } 
            />
          </Grid>
          <Grid item xs={2} sm={2} md={2}>
            <ListItemText secondary={
            <Typography variant="body2" style={{ color: 'red', fontSize: `12px` }}>
              {value.value}</Typography>}/>
          </Grid>
        </Grid>
          
        </ListItem>
        )
      }}
    })}
        </List>
        
      </>
    );
   
}
