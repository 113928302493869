import React, { useState, useCallback, Fragment, useEffect } from "react";
import { Grid, Card, CardContent, Typography } from "@mui/material";
import {
    ComposedChart,
    ResponsiveContainer,
    CartesianGrid,
    Bar,
    Tooltip
  } from "recharts";
import DataServices from "../../Services/DataServices";
import { useTheme } from '@mui/material/styles';


function SeverityBasedView(){
    const theme = useTheme();
    const [severityPercent, setSeverityPercent] = React.useState({});
    const [sectorChart, setSectorChart] = React.useState({});
    const [criticalCount, setCriticalCount] = React.useState(0);


    useEffect(() => {
        retrieveData();
      },[])

    const setData = (data) => {
        setSeverityPercent({critical: data.criticalPercentage, moderate: data.moderatePercentage, clean: data.cleanPercentage})
        setCriticalCount(data.criticalCount);
        setSectorChart(data.severityChart);
    }

    const retrieveData = async() => {
        await DataServices.getSectorSeverity()
          .then((response) => {
            //console.log('response from getSectorSeverity: '+JSON.stringify(response.data.data));
            setData(response.data.data);
          })
          .catch((e) => {
            console.log(e);
          });
      };


      const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
          return (
              <div className="custom-tooltip">
                <Typography>{`${payload[0].payload.name} : ${payload[0].value}`}</Typography>
              </div>
          );
        }
        return null;
      };


      return(
        <Grid 
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        >
            <Grid item xs={12} sm={4} md={4}>
                <Card style={{backgroundColor: theme.palette.success.dark}} >
                    <CardContent style={{padding: `5px`}}>                        
                        <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                       
                        >
                          <Grid item>
                          <Typography>{severityPercent.clean}%</Typography>
                          </Grid>  
                          <Grid item>
                          <Typography>Clean</Typography>
                          </Grid>  
                        </Grid>  
                        <ResponsiveContainer width="100%" height={50}>  
                          <ComposedChart  data={sectorChart}>
                              <CartesianGrid stroke="#f5f5f5" />
                              <Tooltip content={<CustomTooltip />} />
                              <Bar dataKey="clean" barSize={10} fill="#565e6b" />
                          </ComposedChart>
                        </ResponsiveContainer>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
                <Card style={{backgroundColor: theme.palette.warning.dark}}>
                    <CardContent style={{padding: `5px`}}>                        
                        <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        >
                          <Grid item>
                          <Typography>{severityPercent.moderate}%</Typography>
                          </Grid>  
                          <Grid item>
                          <Typography>Moderate</Typography>
                          </Grid>  
                        </Grid>   
                        <ResponsiveContainer width="100%" height={50}>        
                          <ComposedChart data={sectorChart}>
                              <CartesianGrid stroke="#f5f5f5" />
                              <Tooltip content={<CustomTooltip />} />
                              <Bar dataKey="moderate" barSize={10} fill="#565e6b" />
                        </ComposedChart>
                      </ResponsiveContainer>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
                <Card style={{backgroundColor: theme.palette.error.dark}}>
                    <CardContent style={{padding: `5px`}}>                        
                        <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        >
                          <Grid item>
                          <Typography>{severityPercent.critical}%</Typography>
                          </Grid>  
                          <Grid item>
                          <Typography>Critical</Typography>
                          </Grid>  
                        </Grid>    
                        <ResponsiveContainer width="100%" height={50}>       
                            <ComposedChart data={sectorChart}>
                              <CartesianGrid stroke="#f5f5f5" />
                              <Tooltip content={<CustomTooltip />} />
                              <Bar dataKey="critical" barSize={10} fill="#565e6b" />
                            </ComposedChart>
                        </ResponsiveContainer>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
      );
     

}

export default SeverityBasedView