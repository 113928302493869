const sizeConfigs = {
  sidebar: {
    width: "15rem",
    xsWidth: "3rem"
  }
};



export default sizeConfigs;
