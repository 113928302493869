import React from 'react';
import CleanIcon from '../../Assets/Clean.svg';
import ModerateIcon from '../../Assets/Moderate.svg';
import CriticalIcon from '../../Assets/Critical.svg';
import CleanFlood from '../../Assets/Flooding.svg'
import FloodGiff from '../../Assets/output-onlinegiftools.gif'
import ModerateFlood from '../../Assets/FloodingModerate.svg'

const CustomIcon = (props) => {
  let sData = props && props.data && props.data.level;
  let levelBasedCheck = sData > 80 ? "critical": sData > 50 ?  "moderate" : "clean"
  let type =  props && props.data && props.data.type;
  let zoom = props?.zoom < 12 ? props.zoom  : 12

  const getIconSize = (zoom) => {
    return Math.pow(9 - zoom, 2) * 5; // Adjust the size as needed
  };

    if(type == 'FLOODING'){
      return (
        <div>
           {sData  == "critical" &&(
             <img src={FloodGiff} 
             style={{
              width: `${getIconSize(zoom)}px`,
              height: `${getIconSize(zoom)}px`,
             }}/>
          )}
          {sData  == "moderate" &&(
              <img src={ModerateFlood} 
              style={{
                width: `${getIconSize(zoom)}px`,
                height: `${getIconSize(zoom)}px`,
               }}/>
          )}
          {sData  == "clean" &&(
            <img src={CleanFlood} 
            style={{
              width: `${getIconSize(zoom)}px`,
              height: `${getIconSize(zoom)}px`,
             }}/>
          )}
        </div>
      );
    }
    else{
      return (
        <div>
          {levelBasedCheck  == "critical" &&(
            <img src={CriticalIcon} 
            style={{
              width: `${getIconSize(zoom)}px`,
              height: `${getIconSize(zoom)}px`,
             }}/> 
          )}
          {levelBasedCheck  == "moderate" &&(
            <img src={ModerateIcon} 
            style={{
              width: `${getIconSize(zoom)}px`,
              height: `${getIconSize(zoom)}px`,
             }}/> 
          )}
          {levelBasedCheck  == "clean" &&(
            <img src={CleanIcon} 
              style={{
              width: `${getIconSize(zoom)}px`,
              height: `${getIconSize(zoom)}px`,
             }}/> 
          )}
          
        </div>
      );
    }
  
  
};

export default CustomIcon;
