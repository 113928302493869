import * as React from 'react';
import { Snackbar,  AlertTitle } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import { Info } from '@mui/icons-material';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


export default function ToastController(props) {
    const { isOpen, message,  onClose, status, hALign, vAlign } = props;
    return (
        <Snackbar
            key={Math.random()}
            anchorOrigin={{
                vertical: vAlign ? vAlign : 'bottom',
                horizontal: hALign? hALign : 'right'
            }}
            open={isOpen}
            autoHideDuration={6000}
            onClose={onClose}
        >
          <Alert severity={status}   color={status=='success'? 'primary' : (status=='error'|| status=='deactivated')? 'error' : 'secondary'}>
            <AlertTitle>{status && status.toUpperCase()}</AlertTitle>
            {message}
          </Alert>
        </Snackbar>
      );
}