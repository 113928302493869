const DropDownList = [    
    {
        id:'ODCinfo', 
        name: 'MS-7505'
    },
    {
        id:'mcODCinfo', 
        name: 'FBU3650U'
    },
    {
        id:'odcai_track2', 
        name: 'MAN XE-5012-Y'
    },
    {
        id:'odcai_track3', 
        name: 'MS YR-1069-E'
    },
    {
        id:'rfid_track', 
        name: 'BIN YN-7015-S'
    },
    /* {
        id:'dicSensor', 
        name: 'DIC SENSOR'
    },
    {
        id:'bin', 
        name: 'Bin'
    }, */
    // {
    //     id:'smartCombi', 
    //     name: 'SMART COMBI ARM'
    // }
]

//mark as false positive

export default DropDownList;