import http from "./http-common";
import authHeader from "./AuthHeader";

const getSectorSeverity = (params) => {
  return http.get("geo/sector/severity", { headers: authHeader(), params });
};
const getStreetSeverity = (params) => {
  return http.get("geo/streets/severity", { headers: authHeader(), params });
};
const getLapsesCount = () => {
  return http.get("geo/lapses/count", { headers: authHeader() });
};
const getAssetCount = () => {
  return http.get("geo/assets/count", { headers: authHeader() });
};
const getRouteSeverity = () => {
  return http.get("geo/routes/severity", { headers: authHeader() });
};
const getRoutes = () => {
  return http.get("/route/", { headers: authHeader() });
};
const getRegionInfo = () => {
  return http.get("/region/", { headers: authHeader() });
};
const getMapView = (params) => {
  return http.get("/geo/map/plots/view", { headers: authHeader(), params });
};

const getDicView = (params) => {
  let url = "/devices";
  let finalUrl = params ? url + `?deviceType=${params}` : url;
  return http.get(finalUrl, { headers: authHeader() });
};

const getAssetRouteView = (params) => {
  return http.get("/geo/assets/route", { headers: authHeader(), params });
};
const getAssetRouteStreetView = (params) => {
  return http.get("/geo/assets/streets", { headers: authHeader(), params });
};
const getLapseRouteView = (params) => {
  return http.get("/geo/lapse/route", { headers: authHeader(), params });
};
const getLapseRouteStreetView = (params) => {
  return http.get("/geo/lapse/streets", { headers: authHeader(), params });
};
const getLitterData = (params) => {
  return http.get("/geo/litter/data", { headers: authHeader(), params });
};
const getYearlySectorData = (params) => {
  return http.get("/sector/yearly", { headers: authHeader(), params });
};
const getMonthlySectorData = (params) => {
  return http.get("/sector/monthly", { headers: authHeader(), params });
};
const getYearlyRouteData = (params) => {
  return http.get("/route/yearly", { headers: authHeader(), params });
};
const getMonthlyRouteData = (params) => {
  return http.get("/route/monthly", { headers: authHeader(), params });
};
const getYearlyAssetsData = (params) => {
  return http.get("/assets/yearly", { headers: authHeader(), params });
};
const getMonthlyAssetsData = (params) => {
  return http.get("/assets/monthly", { headers: authHeader(), params });
};
const getYearlyLapsesData = (params) => {
  return http.get("/lapses/yearly", { headers: authHeader(), params });
};
const getMonthlyLapsesData = (params) => {
  return http.get("/lapses/monthly", { headers: authHeader(), params });
};
const getYearlyStreetsData = (params) => {
  return http.get("/street/yearly", { headers: authHeader(), params });
};
const getMonthlyStreetsData = (params) => {
  return http.get("/street/monthly", { headers: authHeader(), params });
};
const getSectorTrendByYear = (params) => {
  const year = params.year;
  return http.get(`/sector/year/${year}`, { headers: authHeader() });
};
const getSectorTrendByMonthly = (params) => {
  return http.get(`/sector/yearly/trend`, { headers: authHeader(), params });
};
const getSectorTrendByDays = (params) => {
  return http.get(`/sector/day/trend`, { headers: authHeader(), params });
};

const getRouteTrendByYear = (params) => {
  const year = params.year;
  return http.get(`/route/year/${year}`, { headers: authHeader() });
};

const getRouteTrendByMonthly = (params) => {
  return http.get(`/route/yearly/trend`, { headers: authHeader(), params });
};
const getRouteTrendByDays = (params) => {
  return http.get(`/route/day/trend`, { headers: authHeader(), params });
};

const getStreetTrendByYear = (params) => {
  const year = params.year;
  return http.get(`/street/year/${year}`, { headers: authHeader(), params });
};
const getStreetTrendByMonthly = (params) => {
  return http.get(`/street/yearly/trend`, { headers: authHeader(), params });
};
const getStreetTrendByDays = (params) => {
  return http.get(`/street/day/trend`, { headers: authHeader(), params });
};

const getAssetTrendByYear = (params) => {
  const year = params.year;
  return http.get(`/assets/year/${year}`, { headers: authHeader(), params });
};
const getAssetTrendByMonthly = (params) => {
  return http.get(`/assets/yearly/trend`, { headers: authHeader(), params });
};
const getAssetTrendByDays = (params) => {
  return http.get(`/assets/day/trend`, { headers: authHeader(), params });
};
const getLapseTrendByYear = (params) => {
  const year = params.year;
  return http.get(`/lapses/year/${year}`, { headers: authHeader(), params });
};
const getLapseTrendByMonthly = (params) => {
  return http.get(`/lapses/yearly/trend`, { headers: authHeader(), params });
};
const getLapseTrendByDays = (params) => {
  return http.get(`/lapses/day/trend`, { headers: authHeader(), params });
};
const addUser = (data) => {
  return http.post(`/user/add`, data, { headers: authHeader() });
};
const updateRegionSector = (data) => {
  return http.put(`/region/update`, data, { headers: authHeader() });
};
const getUser = (params) => {
  const emailId = params.emailId;
  return http.get(`/user/email/${emailId}`, { headers: authHeader() });
};
const getUnassignedStreets = (params) => {
  return http.get(`/geo/street/unassigned`, { headers: authHeader() });
};

const updateFalsePositiveStatus = (data) => {
  return http.put(`/geo/status`, data, { headers: authHeader() });
};
const getRfIdScannedData = (params) => {
  return http.get(`devices/rfid/data`, { headers: authHeader(), params });
};

const getRfIdLogsList = (params) => {
  return http.get(`devices/rfid/logs`, { headers: authHeader(), params });
};

const downloadRfIdLogsList = (params) => {
  return http.get(`devices/rfid/csv`, { headers: authHeader(), params });
};

// other CRUD methods

export default {
  getSectorSeverity,
  getStreetSeverity,
  getLapsesCount,
  getAssetCount,
  getRouteSeverity,
  getRoutes,
  getMapView,
  getAssetRouteView,
  getAssetRouteStreetView,
  getLapseRouteView,
  getLapseRouteStreetView,
  getLitterData,
  getYearlySectorData,
  getMonthlySectorData,
  getYearlyRouteData,
  getMonthlyRouteData,
  getYearlyAssetsData,
  getMonthlyAssetsData,
  getYearlyLapsesData,
  getMonthlyLapsesData,
  getYearlyStreetsData,
  getMonthlyStreetsData,
  getSectorTrendByYear,
  getSectorTrendByMonthly,
  getSectorTrendByDays,
  getRouteTrendByYear,
  getRouteTrendByMonthly,
  getRouteTrendByDays,
  getStreetTrendByYear,
  getStreetTrendByMonthly,
  getStreetTrendByDays,
  getAssetTrendByYear,
  getAssetTrendByMonthly,
  getAssetTrendByDays,
  getLapseTrendByYear,
  getLapseTrendByMonthly,
  getLapseTrendByDays,
  addUser,
  getUser,
  getRegionInfo,
  getUnassignedStreets,
  updateRegionSector,
  getDicView,
  updateFalsePositiveStatus,
  getRfIdScannedData,
  getRfIdLogsList,
  downloadRfIdLogsList,
};
