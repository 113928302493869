const ProjectList = [
    {
        label: "IPC-NE",
        value: "IPC-NE",
    },
    {
        label: "IPC-CS",
        value: "IPC-CS",
    },
    {
        label: "IPC-Expressway",
        value: "IPC-Expressway",
    },
    
]

export default ProjectList